import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Header Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/header/"
    metaDescription="Browse through all the different mobile app and website header templates to create an engaging user experience for your projects."
    description="
    h2:Pre-made headers for your mobile and web apps
    <br/>
    Create an engaging user experience by dragging-and-dropping header templates in Uizard.
    <br/>
    Browse through all the different mobile app and website header templates to create an engaging user experience for your projects.
    All header templates in Uizard are fully customizable with no design experience required.
    <br/>
    h3:Easily style header templates for your unique project requirements
    <br/>
    Header templates in Uizard are fully resizable and customizable to suit any design.
    All of the content and components are completely editable, and the style can always be adjusted to reflect your needs.
    <br/>
    h3:Uizard's header templates are quick and simple to edit 
    <br/>
    They're perfect for rapid prototype creation! Save yourself from brainstorming and designing a header by simply dragging-and-dropping one of Uizard's pre-made header templates. 
    <br/>
    There are numerous header component template currently available in Uizard, and the list is continuously growing.
    "
    pages={[
      "Thick app nav bar",
      "Simple top nav",
      "Web app header",
      "Web app header ",
      "Header hero signup",
      "App header and stories",
      "Simple app header",
      "App top nav search",
      "App hero with icons",
      "Web app top bar",
      "App nav with back button",
      "App header with title",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing app headers"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing app headers"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing app headers"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing app headers"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing app headers"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/component-templates/header/header_1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/component-templates/header/header_2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/component-templates/header/header_3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/component-templates/header/header_4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/component-templates/header/header_5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
